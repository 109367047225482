// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.header {
    display: flex;
    padding-left: calc(var(--px) / 2);
    padding-right: calc(var(--px) / 2);
    margin-top: var(--px);
    gap: 5px;
}

.search__bar {
    outline: none;
    border: none;
    color: var(--tg-theme-text-color);
    background-color: var(--tg-theme-secondary-bg-color);
    width: 100%;
    padding: calc(var(--px) / 3);
}

.search__icon {
    width: 1rem;
    height: 1rem;
    align-self: center;
}

.search__container {
    width: 100%;
    background-color: var(--tg-theme-secondary-bg-color);
    display: flex;
    border-radius: var(--px);
    padding-right: var(--px);
    padding-left: calc((var(--px) / 2) + 5px);
}

.text-blue:before {
    border-bottom: none !important;
}`, "",{"version":3,"sources":["webpack://./src/Components/Header/Header.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,iCAAiC;IACjC,kCAAkC;IAClC,qBAAqB;IACrB,QAAQ;AACZ;;AAEA;IACI,aAAa;IACb,YAAY;IACZ,iCAAiC;IACjC,oDAAoD;IACpD,WAAW;IACX,4BAA4B;AAChC;;AAEA;IACI,WAAW;IACX,YAAY;IACZ,kBAAkB;AACtB;;AAEA;IACI,WAAW;IACX,oDAAoD;IACpD,aAAa;IACb,wBAAwB;IACxB,wBAAwB;IACxB,yCAAyC;AAC7C;;AAEA;IACI,8BAA8B;AAClC","sourcesContent":[".header {\r\n    display: flex;\r\n    padding-left: calc(var(--px) / 2);\r\n    padding-right: calc(var(--px) / 2);\r\n    margin-top: var(--px);\r\n    gap: 5px;\r\n}\r\n\r\n.search__bar {\r\n    outline: none;\r\n    border: none;\r\n    color: var(--tg-theme-text-color);\r\n    background-color: var(--tg-theme-secondary-bg-color);\r\n    width: 100%;\r\n    padding: calc(var(--px) / 3);\r\n}\r\n\r\n.search__icon {\r\n    width: 1rem;\r\n    height: 1rem;\r\n    align-self: center;\r\n}\r\n\r\n.search__container {\r\n    width: 100%;\r\n    background-color: var(--tg-theme-secondary-bg-color);\r\n    display: flex;\r\n    border-radius: var(--px);\r\n    padding-right: var(--px);\r\n    padding-left: calc((var(--px) / 2) + 5px);\r\n}\r\n\r\n.text-blue:before {\r\n    border-bottom: none !important;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
