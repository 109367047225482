// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.card {
    display: flex;
    flex-direction: column;
    padding-top: .8rem;
    padding-bottom: .8rem;
    border-radius: 15px;
    transition: all .2s;
}

.card:hover {
    background-color: var(--tg-theme-secondary-bg-color);
}

.image_container {
    width: 100%;
    overflow: hidden;
}

.card__image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 10px;
}

.card__title {
    display: flex;
    justify-content: space-between;
    width: 100%;
}

h4 {
    margin: 0;
}
.base-Menu-listbox {
    background: var(--tg-theme-bg-color) !important;
    color: var(--tg-theme-text-color) !important;
    border: 1px solid var(--tg-theme-section-separator-color) !important;
}`, "",{"version":3,"sources":["webpack://./src/Components/Card/Card.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,kBAAkB;IAClB,qBAAqB;IACrB,mBAAmB;IACnB,mBAAmB;AACvB;;AAEA;IACI,oDAAoD;AACxD;;AAEA;IACI,WAAW;IACX,gBAAgB;AACpB;;AAEA;IACI,WAAW;IACX,YAAY;IACZ,iBAAiB;IACjB,mBAAmB;AACvB;;AAEA;IACI,aAAa;IACb,8BAA8B;IAC9B,WAAW;AACf;;AAEA;IACI,SAAS;AACb;AACA;IACI,+CAA+C;IAC/C,4CAA4C;IAC5C,oEAAoE;AACxE","sourcesContent":[".card {\r\n    display: flex;\r\n    flex-direction: column;\r\n    padding-top: .8rem;\r\n    padding-bottom: .8rem;\r\n    border-radius: 15px;\r\n    transition: all .2s;\r\n}\r\n\r\n.card:hover {\r\n    background-color: var(--tg-theme-secondary-bg-color);\r\n}\r\n\r\n.image_container {\r\n    width: 100%;\r\n    overflow: hidden;\r\n}\r\n\r\n.card__image {\r\n    width: 100%;\r\n    height: 100%;\r\n    object-fit: cover;\r\n    border-radius: 10px;\r\n}\r\n\r\n.card__title {\r\n    display: flex;\r\n    justify-content: space-between;\r\n    width: 100%;\r\n}\r\n\r\nh4 {\r\n    margin: 0;\r\n}\r\n.base-Menu-listbox {\r\n    background: var(--tg-theme-bg-color) !important;\r\n    color: var(--tg-theme-text-color) !important;\r\n    border: 1px solid var(--tg-theme-section-separator-color) !important;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
