// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.card__1x1 {
    width: calc(50% - (var(--px) * 2));
    height: 100%;
    cursor: pointer;
    border-radius: 15px;
    transition: all .2s;
    display: flex;
    flex-direction: column;
}


.card__1x1:hover {
    background-color: var(--tg-theme-secondary-bg-color);
}

.image_container {
    width: 100%;
    overflow: hidden;
}

.card__1x1__image {
    width: calc(50vw - (2 * var(--px)));
    height: calc(50vw - (2 * var(--px)));
    object-fit: cover;
    aspect-ratio: 1 / 1;
    border-radius: 1.5rem;
}

.card__1x1__title {
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.text__center {
    display: flex;
    justify-content: center;
    text-align: center;
}

h4 {
    margin: 0;
}`, "",{"version":3,"sources":["webpack://./src/Components/Game/Game.css"],"names":[],"mappings":"AAAA;IACI,kCAAkC;IAClC,YAAY;IACZ,eAAe;IACf,mBAAmB;IACnB,mBAAmB;IACnB,aAAa;IACb,sBAAsB;AAC1B;;;AAGA;IACI,oDAAoD;AACxD;;AAEA;IACI,WAAW;IACX,gBAAgB;AACpB;;AAEA;IACI,mCAAmC;IACnC,oCAAoC;IACpC,iBAAiB;IACjB,mBAAmB;IACnB,qBAAqB;AACzB;;AAEA;IACI,aAAa;IACb,8BAA8B;IAC9B,WAAW;AACf;;AAEA;IACI,aAAa;IACb,uBAAuB;IACvB,kBAAkB;AACtB;;AAEA;IACI,SAAS;AACb","sourcesContent":[".card__1x1 {\r\n    width: calc(50% - (var(--px) * 2));\r\n    height: 100%;\r\n    cursor: pointer;\r\n    border-radius: 15px;\r\n    transition: all .2s;\r\n    display: flex;\r\n    flex-direction: column;\r\n}\r\n\r\n\r\n.card__1x1:hover {\r\n    background-color: var(--tg-theme-secondary-bg-color);\r\n}\r\n\r\n.image_container {\r\n    width: 100%;\r\n    overflow: hidden;\r\n}\r\n\r\n.card__1x1__image {\r\n    width: calc(50vw - (2 * var(--px)));\r\n    height: calc(50vw - (2 * var(--px)));\r\n    object-fit: cover;\r\n    aspect-ratio: 1 / 1;\r\n    border-radius: 1.5rem;\r\n}\r\n\r\n.card__1x1__title {\r\n    display: flex;\r\n    justify-content: space-between;\r\n    width: 100%;\r\n}\r\n\r\n.text__center {\r\n    display: flex;\r\n    justify-content: center;\r\n    text-align: center;\r\n}\r\n\r\nh4 {\r\n    margin: 0;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
